/**
 * Date utils
 */
import format from 'date-fns/format';
import { DATE_FORMAT } from 'src/config/configString';

/**
 * formatDate
 */
export const formatDateString = (value: string, fallback = '-') => {
  if (!value) return fallback;
  return formatDate(new Date(value));
};

export const safeFormatDateString = (value: string, fallback = '-') => {
  if (!value) return fallback;
  try {
    return formatDate(new Date(value));
  } catch (error) {
    return value;
  }
};

export const formatDate = (value: Date, fallback = '-') => {
  if (!value) return fallback;
  return format(value, DATE_FORMAT);
};

export const newFormatDateString = (dateString: string): string => format(new Date(dateString), 'd-MMM-yyyy h:mma');

export const newFormatDateTimeString = (dateString: string): string => format(new Date(dateString), 'dd-MMM-yyyy HH:mm:ss');
