import { ReactNode, createContext, useContext, useMemo, useState, useCallback } from 'react';
import { ShipmentInActionDetailDto } from 'src/generated/services/TFinancialApi';
import { IPerformShipmentWorkflowActionArgs, useIsShipmentAsyncProcessing, usePerformShipmentWorkflowAction } from 'src/use-cases/invoice/useInvoices';

export interface IRecalculationToastSelectorProps {
  children: ReactNode;
}

export interface IRecalculationToastValues {
  isLoading: boolean;
  isShowNotification: boolean;
  shipmentInAction: ShipmentInActionDetailDto | null;
  confirmAsyncShipment(props: IPerformShipmentWorkflowActionArgs): Promise<void>;
}

// eslint-disable-next-line react-refresh/only-export-components
export const RecalculationToastDefaultValues = {
  isLoading: false,
  isShowNotification: false,
  shipmentInAction: null,
  confirmAsyncShipment: async () => {},
};

export const RecalculationToastSelectorContext = createContext<IRecalculationToastValues>(RecalculationToastDefaultValues);

export const RecalculationToastSelector = ({ children }: IRecalculationToastSelectorProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const [shipmentInAction, setShipmentInAction] = useState<ShipmentInActionDetailDto | null>(null);

  const { mutateAsync: performShipmentWorkflowAction } = usePerformShipmentWorkflowAction();
  const { mutateAsync: isShipmentAsyncProcessing } = useIsShipmentAsyncProcessing();

  const confirmAsyncShipment = useCallback(
    async (props: IPerformShipmentWorkflowActionArgs) => {
      setIsLoading(true);
      const response = await performShipmentWorkflowAction(props);

      if (response?.data?.data?.asyncProcessing) {
        const checkProcessingStatus = async () => {
          const isAsyncProcessing = await isShipmentAsyncProcessing(props.shipmentInvoiceId);

          if (isAsyncProcessing) {
            setTimeout(checkProcessingStatus, 1000); // Check again after 1 second
          } else {
            const response2 = await performShipmentWorkflowAction(props);

            setShipmentInAction(response2?.data?.data);
            setIsShowNotification(true);
            setIsLoading(false);
          }
        };

        await checkProcessingStatus();
      } else {
        setIsLoading(false);
      }
    },
    [isShipmentAsyncProcessing, performShipmentWorkflowAction]
  );

  const value = useMemo(
    () => ({
      isLoading,
      isShowNotification,
      shipmentInAction,
      confirmAsyncShipment,
    }),
    [isLoading, isShowNotification, shipmentInAction, confirmAsyncShipment]
  );

  return <RecalculationToastSelectorContext.Provider value={value}>{children}</RecalculationToastSelectorContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export function useRecalculationToastSelector() {
  const context = useContext(RecalculationToastSelectorContext);

  if (!context) {
    throw new Error(`Recalculation Toast selector compound components cannot be rendered outside the Recalculation Toast Selector component`);
  }
  return context;
}
